import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '@app/data/services/user.service';
import { AppStateService } from '../services/app-state.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private loaderService: LoaderService,
        private router: Router,
        private dialog: MatDialog,
        private appStateService: AppStateService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(() => {
                next.handle(req);
            }),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    const errorMessage = error.error?.detail || error.error?.error || error.message;
                    if (error.status === 401) {
                        this.userService.purgeAuth();
                        this.appStateService.resetUserInfo();
                        this.dialog.closeAll();

                        switch (errorMessage) {
                            case 'Authentication credentials were not provided.':
                                {
                                    setTimeout(() => {
                                        this.router.navigate(['/login/session-expired'], {
                                            queryParams: { errorMessage: 'Session is expired' },
                                        });
                                    }, 0);
                                }
                                break;
                            default:
                                {
                                    if (error.url.endsWith('me/') || this.isTrialExpiredMessage(errorMessage)) {
                                        if (errorMessage) {
                                            this.router.navigate(['/login'], { queryParams: { errorMessage } });
                                        } else {
                                            this.router.navigate(['/login']);
                                        }
                                    }
                                }
                                break;
                        }
                    }

                    if (error.status === 403) {
                        if (error.url.endsWith('me/')) {
                            this.appStateService.logoutUser();
                            this.appStateService.resetUserInfo();
                        }

                        if (this.appStateService.currentUrl() === '/select-silo') {
                            this.appStateService.resetUserInfo();
                            this.router.navigate(['/login'], {
                                queryParams: { errorMessage: 'You do not have access to this silo' },
                            });
                        }
                    }

                    this.loaderService.hideLoader();
                }

                return throwError(() => error);
            }),
        );
    }

    private isTrialExpiredMessage(errorMessage: unknown) {
        if (typeof errorMessage === 'string') {
            return errorMessage.includes('trial') || errorMessage.includes('demo');
        }
        return false;
    }
}
